import { useLazyQuery, useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { areEqualObjects, downloadCSV, exportCSV } from '../lib';

export const useCSVExporter = ({
  jql,
  csvName,
  type,
  responseAttr = '',
  variables,
  locale,
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [queryVariables, setQueryVariables] = useState(variables);
  const client = useApolloClient();

  const handleCompleteExport = (dataLoaded) => {
    const csv = exportCSV({ type, data: dataLoaded, locale });
    downloadCSV(csv, csvName || type);
    setIsExporting(false);
  };

  // adding consumer prce and fuarantee price column value to XL
  const additionalDataForXL = (dataCompleted) => {
    let modifiedData = dataCompleted?.map((element) => {
      const offerAmount = element?.appraisals?.[0]?.offer ?? '';
      const guaranteedPriceAmount =
        element?.appraisals?.[0]?.dealer_guaranteed_price ?? '';
      element = {
        ...element,
        consumer_price: offerAmount,
        guaranteed_price: guaranteedPriceAmount,
      };
      return element;
    });
    return modifiedData;
  };

  const [getData] = useLazyQuery(jql, {
    errorText: locale.leadExportCSVError,
    variables: queryVariables,
    onCompleted: (data) => {
      const modifiedData = additionalDataForXL(data[responseAttr] || data);
      handleCompleteExport(modifiedData);
    },
  });

  const getExportedCSV = (vars) => {
    setIsExporting(true);
    const areEquals = areEqualObjects(queryVariables, vars);
    const cachedData = client.cache.readQuery({
      query: jql,
      variables: vars,
    });

    vars && setQueryVariables(vars);

    if (!cachedData || !areEquals) {
      getData({ variables: vars });
    } else {
      const modifiedData = additionalDataForXL(
        cachedData[responseAttr] || cachedData
      );
      handleCompleteExport(modifiedData);
    }
  };

  return [isExporting, getExportedCSV];
};
