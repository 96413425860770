import ACVQuestionsJSON from '@portal/static/disclosure/acv-questions.json';

import { checkNumberString } from './number';
import { reverseObjectProperties } from './object';
import { SourcePlatform } from '@portal/lead/utils';

/** Use init value to determine if an answer has been changed or cleared */
export const DisclosureInitValue = null;

export const Transmission = {
  Auto: 'Auto',
  Manual: 'Manual',
};

export const Features = {
  Leather: 'Leather',
  Navigation: 'Navigation',
  Sunroof: 'Sunroof',
};

export const Occurrence = {
  Zero: '0',
  Once: '1',
  Many: '2+',
};

export const DisclosureBoolean = {
  Yes: 'yes',
  No: 'no',
};

export const TireTread = {
  Poor: 'Poor',
  Fair: 'Fair',
  Good: 'Good',
};

export const DisclosureSeverity = {
  Minor: 'Minor',
  Moderate: 'Moderate',
  Major: 'Major',
};

export const EngineIssue = {
  NoStart: 'Does Not Start',
  EngineNoise: 'Engine Noise',
  Smoke: 'Excessive Smoke',
  Rough: 'Runs Rough',
  NotRunning: "Won't Stay Running",
};

export const InteriorIssue = {
  Airbag: 'Airbag',
  Electronics: 'Electronics',
  Odor: 'Odor',
  Seat: 'Seat Damage',
  Other: 'Other Damage',
};

export const MechanicalIssue = {
  AC: 'AC',
  Head: 'Head Gasket',
  Oil: 'Oil Leak',
  Steering: 'Steering',
  Suspension: 'Suspension',
  Transmission: 'Transmission',
};

export const WarningLight = {
  Airbag: 'Airbag',
  Battery: 'Battery',
  Brakes: 'Brakes',
  Engine: 'Engine',
  Pressure: 'Pressure',
  Traction: 'Traction',
};

export const TireIssue = {
  Damaged: 'Damaged',
  Incorrect: 'Incorrect Size',
  Mismatched: 'Mismatched',
  Missing: 'Missing Spare',
  Uneven: 'Uneven Wear',
};

export const CustomWorkflow = {
  Paint: 'Paint',
  Exhaust: 'Exhaust',
  Performance: 'Performance',
  Suspension: 'Suspension',
  Wheels: 'Wheels',
  Radio: 'Radio',
};

/**
 * Map ACV disclosure answer keys to form keys
 *
 * 1. Key: ACV disclosure answer key
 * 2. Value: disclosure form key
 */
const ACVDisclosureFormMap = {
  accident: 'accident',
  body_damage_severity: 'body_damage_option',
  body_damage: 'body_damage',
  engine_issues_types: 'engine_issue_option',
  engine_issues: 'engine_issue',
  frame_damage: 'frame_damage',
  glass_damage: 'glass_damage',
  interior_issue_types: 'interior_issue_option',
  interior_issues: 'interior_issue',
  mechanical_issue_types: 'mechanical_issue_option',
  mechanical_issues: 'mechanical_issue',
  modifications_types: 'custom_work_option',
  modifications: 'custom_work',
  odometer: 'odometer',
  option_equipment: 'feature',
  rust_damage_severity: 'rust_damage_option',
  rust_damage: 'rust_damage',
  tire_issues_types: 'tire_issue_option',
  tire_issues: 'tire_issue',
  tire_tread: 'tire_tread',
  title_branded: 'title_issue',
  title_present: 'title_present',
  transmission: 'transmission',
  warning_lights_types: 'warning_light_option',
  warning_lights: 'warning_light',
  keys: 'keys',
};

export const ReverseACVDisclosureFormMap =
  reverseObjectProperties(ACVDisclosureFormMap);

/**
 * Map Clearcar disclosure answer keys to form keys
 *
 * 1. Key: Clearcar disclosure answer key
 * 2. Value: disclosure form key
 */
const ClearCarDisclosureFormMap = {
  accidents: 'accident',
  keys: 'keys',
};

export const ReverseClearCarDisclosureFormMap = reverseObjectProperties(
  ClearCarDisclosureFormMap
);

const QuestionSource = {
  ACV: 'acv',
  ClearCar: 'clearcar',
};

const DisclosureQuestionType = {
  Boolean: 'boolean',
  Checkbox: 'checkbox',
  Int: 'int',
  Radio: 'radio',
  String: 'string',
};

const DisclosureBooleanType = {
  True: 'Yes',
  False: 'No',
};

const MLKey = {
  Engine: 'engine',
  OptionEquipment: 'option_equipment',
  UVC: 'uvc',
};

//key array only for lux leads
const keysArray = [
  {
    question: {
      title: 'Number of keys',
      description: 'How many keys does your vehicle have?',
      order: 20,
      ml_key: 'keys',
      key: 'keys',
      id: 183,
      subquestions: [],
      type: 'string',
      options: [
        {
          order: 1,
          id: 90,
          ml_key: 'keys',
          description: '0',
        },
        {
          order: 2,
          id: 91,
          ml_key: 'keys',
          description: '1',
        },
        {
          order: 3,
          id: 92,
          ml_key: 'keys',
          description: '2+',
        },
      ],
    },
  },
];

const serializeValue = (value) => {
  if (value === true || value === 'Yes') {
    return DisclosureBoolean.Yes;
  }

  if (value === false || value === 'No') {
    return DisclosureBoolean.No;
  }

  if (typeof value === 'number') {
    return `${value}`;
  }

  return value;
};

/** Transform disclosure into form compatible */
export const serializeAnswers = ({ acvAnswers, clearCarAnswers }) => {
  let obj = {};

  // Transform the ACV disclosure data info form recognizable data
  if (acvAnswers) {
    Object.entries(acvAnswers).forEach(([key, value]) => {
      const formKey = ACVDisclosureFormMap[key];
      if (formKey) {
        obj[formKey] = serializeValue(value);
      }
    });
  }

  // Transform ClearCar disclosure data
  if (clearCarAnswers) {
    Object.entries(clearCarAnswers).forEach(([key, value]) => {
      const formKey = ClearCarDisclosureFormMap[key];
      if (formKey) {
        obj[formKey] = Array.isArray(value) ? value[0] : value;
      }
    });
  }

  return obj;
};

const deserializeValue = (value) => {
  if (value === DisclosureBoolean.Yes) {
    return true;
  }

  if (value === DisclosureBoolean.No) {
    return false;
  }

  const isNumber = checkNumberString(value);
  if (isNumber) {
    return Number(value);
  }

  return value;
};

/** Transform form data into disclosure compatible */
export const deserializeAnswers = (formAnswers, mapData) => {
  let obj = {};

  Object.entries(formAnswers).forEach(([key, value]) => {
    const formKey = mapData[key];
    if (formKey) {
      obj[formKey] = deserializeValue(value);
    }
  });

  return obj;
};

/** Apply new disclosure answers to original disclosure answers */
export const mergeAnswers = (source, target) => {
  let obj = { ...source };

  Object.entries(target).forEach(([key, value]) => {
    // Apply values if they are not default
    if (value !== DisclosureInitValue) {
      obj[key] = value;
    }
  });

  return obj;
};

/** Normalize raw JSON questions */
export const transformQuestions = (sourcePlatform, mergedACVAnswers) => {
  const isAmazonLead = sourcePlatform === SourcePlatform.Amazon;
  let data = [...ACVQuestionsJSON];
  if ((isAmazonLead && mergedACVAnswers?.keys >= 0) || mergedACVAnswers?.keys) {
    data = [...ACVQuestionsJSON, ...keysArray];
  }

  // List of normalized questions with required or unsupported checks
  let questionsNormalized = [];

  // By key is used to quickly access questions without looping original source
  const questionsByKey = {};

  const questions = data.map(({ question: item, source }) => {
    const normalizedSubQuestionsList = [];

    const objKey = getQuestionKey(item);

    // Inconsistent labels, set fallback
    const objLabel = item.title || item.description || objKey.replace(/_/, ' ');

    // Transform sub question
    const subQuestionList =
      item.subquestions?.map((subItem) => {
        const subObjOptions = setOptions(subItem.options, subItem.type);
        const subObjKey = getQuestionKey(subItem);

        const isRequired = checkRequired(subObjKey);
        const isUnsupported = checkUnsupported(subObjKey);
        const isHidden = shouldSkipQuestion({
          type: subItem.type,
          key: subObjKey,
        });

        const subObj = {
          answer: getAnswer,
          id: subItem.id,
          isHidden,
          isRequired,
          isUnsupported,
          key: subObjKey,
          label: '',
          options: subObjOptions,
          placeholderLabel: '',
          source,
          subQuestions: [],
          title: subItem.title,
          type: subItem.type,
        };

        const meta = {
          parentKey: objKey,
        };

        questionsByKey[subObjKey] = {
          ...subObj,
          meta,
        };

        normalizedSubQuestionsList.push(questionsByKey[subObjKey]);

        return subObj;
      }) ?? [];

    const objOptions = setOptions(item.options, item.type);
    const isHidden = shouldSkipQuestion({ type: item.type, key: objKey });
    const isRequired = checkRequired(objKey);
    const isUnsupported = checkUnsupported(objKey);

    // Transform main question, use customized label and description if applicable
    const obj = {
      answer: getAnswer,
      description: '',
      id: item.id,
      isHidden,
      isRequired,
      isUnsupported,
      key: objKey,
      label: objLabel,
      options: objOptions,
      source,
      subQuestions: subQuestionList,
      title: item.title,
      type: item.type,
    };

    const meta = {
      childKeys: subQuestionList.map(({ key }) => key),
    };

    questionsByKey[objKey] = {
      ...obj,
      meta,
    };

    // Retain the order of questions and sub questions
    questionsNormalized = [
      ...questionsNormalized,
      questionsByKey[objKey],
      ...normalizedSubQuestionsList,
    ];

    return obj;
  });

  // Filter questions for acv only
  const questionsNormalizedAcv = questionsNormalized.filter(
    ({ source }) => source === QuestionSource.ACV
  );

  // Filter questions for clearcar only
  const questionsNormalizedClearCar = questionsNormalized.filter(
    ({ source }) => source === QuestionSource.ClearCar
  );

  return {
    questions,
    questionsByKey,
    questionsNormalized,
    questionsNormalizedAcv,
    questionsNormalizedClearCar,
  };
};

/** Determine the value to pass as answer to acv pricing engine */
const getAnswer = (data, key) => data[key];

/** `key` field is present but `ml_key` is more consistent with sub options */
const getQuestionKey = ({ ml_key }) => ml_key;

/** Sort and build options */
const setOptions = (options, type) => {
  const isBoolean = type === DisclosureQuestionType.Boolean;

  return (
    options
      ?.sort((optionA, optionB) => {
        // Retain 'Yes' and 'No' order
        if (isBoolean) {
          return 0;
        }

        const labelA = optionA.order;
        const labelB = optionB.order;

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }

        return 0;
      })
      .map(({ id, description }) => {
        let value = description;

        if (isBoolean) {
          // Set value to boolean true/false, instead of using key of main question
          value = description === DisclosureBooleanType.True;
        }

        return {
          id,
          label: description,
          value,
        };
      }) ?? []
  );
};

/** Required fields override, these info are not available on questions json */
const checkRequired = (key) => {
  switch (key) {
    case MLKey.OptionEquipment:
      return false;
    default:
      return true;
  }
};

/** Determine if a question should be omitted on the answer payload */
const checkUnsupported = (key) => {
  switch (key) {
    case MLKey.Engine:
      return true;
    case MLKey.UVC:
      return ({ uvc }) => !uvc;
    default:
      return false;
  }
};

/**
 * Skip string and int types like vin, make and model. These are natively asked
 * in app.
 */
export const shouldSkipQuestion = ({ type, key }) =>
  type === DisclosureQuestionType.String ||
  type === DisclosureQuestionType.Int ||
  key === 'color';

/** Transform answers payload ready to send for processing */
export const transformAnswers = ({ questionsNormalized, answers }) =>
  questionsNormalized.reduce((list, question) => {
    if (
      typeof question.isUnsupported === 'function'
        ? question.isUnsupported(answers)
        : question.isUnsupported
    ) {
      return list;
    }

    let answer = question.answer(answers, question.key);

    const hasValidAnswer =
      (!Array.isArray(answer) || answer?.length !== 0) &&
      typeof answer !== 'undefined' &&
      answer !== null;

    if (!hasValidAnswer) {
      // Set blank for optional answers - string, radio and checkboxes
      answer = question.type === DisclosureQuestionType.String ? '' : [''];
    } else if (question.type === DisclosureQuestionType.Radio) {
      // API requires radio button value to be an array
      answer = [answer];
    }
    if (
      answer === DisclosureBooleanType.True ||
      answer === DisclosureBooleanType.False
    ) {
      answer = answer === DisclosureBooleanType.True;
    }
    //only for keys we need to convert number into string
    if (question?.key === 'keys') {
      answer = typeof answer === 'number' ? String(answer) : answer;
    }

    return [
      ...list,
      {
        answer,
        answerByKey: {
          key: question?.key,
          [question?.key]: answer,
        },
        question: { id: question.id },
      },
    ];
  }, []);
