import { gql } from '@apollo/client';

const VehicleSearchFieldsGql = gql`
  fragment VehicleSearchOutputFields on vehicles {
    id
    contact_handle
    created_at
    inspection_request
    make
    lead_viewed
    appraisals(
      order_by: { created_at: desc }
      where: {
        _or: [
          {
            _and: [
              { sourced_by: { _eq: "online_offer" } }
              {
                vehicle: {
                  source_platform: { _nin: ["lux", "guaranteed_price"] }
                }
              }
            ]
          }
          { vehicle: { source_platform: { _nin: [""] } } }
        ]
      }
      limit: 1
    ) {
      offer
      dealer_guaranteed_price
      disclosures {
        odometer
      }
    }
    appraisals_aggregate(
      where: {
        _or: [
          {
            _and: [
              { sourced_by: { _eq: "online_offer" } }
              {
                vehicle: {
                  source_platform: { _nin: ["lux", "guaranteed_price"] }
                }
              }
            ]
          }
          { vehicle: { source_platform: { _nin: [""] } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    model
    offer
    trim
    year
    vin
    contact {
      id
      mobile
      name
      email
    }
    store {
      id
      name
      province_state
      address
      city
    }
    vehicle_notes(limit: 1, order_by: { created_at: desc }) {
      id
      vehicle_id
      note
      user_info {
        name
      }
      created_at
      updated_at
    }
    notes
    customer_first_name
    customer_last_name
    customer_email
    customer_phone
    meeting {
      vehicle_id
      date
    }
  }
`;

const VehicleSearchStatusFieldsGql = gql`
  fragment VehicleSearchOutputStatusFields on vehicles {
    status {
      vehicle_id

      active
      booked_visit
      closed_lost
      closed_won
      follow_up

      # Display legacy statuses used previously on portal
      dismissed
      disqualified
      dormant
      sent_to_crm
      unavailable

      # Filter out "deprecated" statuses
      # appointment
      # appointment_cancelled
      # appointment_full
      # archived
      # booked_inspection
      # contacted
      # deleted
      # deployed_compliance
      # human_appraised
      # incomplete
      # inspection_completed
      # inspection_requested
      # machine_appraised
      # purchased
      # qualified
      # scheduled_appointment
      # scheduled_inspection

      # Special statuses displayed alongside any status
      # new
    }
  }
`;

export const VehicleLeadSearchNoTermGql = gql`
  ${VehicleSearchFieldsGql}
  ${VehicleSearchStatusFieldsGql}
  query VehicleLeadSearchNoTerm(
    $limit: Int = 50
    $offset: Int = 0
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles: vehicles(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      ...VehicleSearchOutputStatusFields
      source_link
      source_platform
      uuid
      appraisals(
        order_by: { created_at: desc }
        where: {
          _or: [
            {
              _and: [
                { sourced_by: { _eq: "online_offer" } }
                {
                  vehicle: {
                    source_platform: { _nin: ["lux", "guaranteed_price"] }
                  }
                }
              ]
            }
            { vehicle: { source_platform: { _nin: [""] } } }
          ]
        }
        limit: 1
      ) {
        sourced_by
        offer
        dealer_guaranteed_expiry_date
        dealer_guaranteed_price
        dealer_guaranteed_accepted_date
        created_at
        disclosures {
          odometer
        }
      }
      inspections {
        monk_inspection_status
      }
      # Limit to one for thumbnail use
      vehicle_images(
        limit: 1
        where: { label: { _ilike: "%Beauty Shot Left%" } }
      ) {
        id
        link
        label
      }
    }
  }
`;

export const VehicleLeadSearchGql = gql`
  ${VehicleSearchFieldsGql}
  ${VehicleSearchStatusFieldsGql}
  query VehicleLeadSearch(
    $limit: Int = 50
    $offset: Int = 0
    $search: String!
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles(
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      ...VehicleSearchOutputStatusFields
      source_link
      source_platform
      uuid
      appraisals(
        order_by: { created_at: desc }
        where: {
          _or: [
            {
              _and: [
                { sourced_by: { _eq: "online_offer" } }
                {
                  vehicle: {
                    source_platform: { _nin: ["lux", "guaranteed_price"] }
                  }
                }
              ]
            }
            { vehicle: { source_platform: { _nin: [""] } } }
          ]
        }
        limit: 1
      ) {
        sourced_by
        offer
        dealer_guaranteed_expiry_date
        dealer_guaranteed_price
        dealer_guaranteed_accepted_date
        created_at
        disclosures {
          odometer
        }
      }
      inspections {
        monk_inspection_status
      }
      # Limit to one for thumbnail use
      vehicle_images(
        limit: 1
        where: { label: { _ilike: "%Beauty Shot Left%" } }
      ) {
        id
        link
        label
      }
    }
  }
`;

export const VehicleLeadFullSearchGql = gql`
  ${VehicleSearchFieldsGql}
  ${VehicleSearchStatusFieldsGql}
  query VehicleFullLeadSearch(
    $offset: Int = 0
    $search: String!
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate(args: { search: $search }, where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles(
      args: { search: $search }
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      ...VehicleSearchOutputStatusFields
      source_link
      source_platform
    }
  }
`;

export const VehicleLeadsCountWithSearchGql = gql`
  query VehicleLeadsCountWithSearch(
    $search: String!
    $where: vehicles_bool_exp
  ) {
    search_vehicles_aggregate(args: { search: $search }, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const VehicleLeadsCountWithoutSearchGql = gql`
  query VehicleLeadsCountWithoutSearch($where: vehicles_bool_exp) {
    search_vehicles_aggregate: vehicles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const VehicleAppointmentSearchNoTermGql = gql`
  ${VehicleSearchFieldsGql}
  query VehicleAppointmentSearch(
    $limit: Int = 50
    $offset: Int = 0
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate: vehicles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles: vehicles(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      moved_to_appointment_at
    }
  }
`;

export const VehicleAppointmentSearchGql = gql`
  ${VehicleSearchFieldsGql}
  query VehicleAppointmentSearch(
    $limit: Int = 50
    $offset: Int = 0
    $search: String!
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate(args: { search: $search }, where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles(
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      moved_to_appointment_at
    }
  }
`;

export const VehicleAppointmentFullSearchGql = gql`
  ${VehicleSearchFieldsGql}
  query VehicleAppointmentFullSearch(
    $limit: Int = 50
    $offset: Int = 0
    $search: String!
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate(args: { search: $search }, where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles(
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      moved_to_appointment_at
    }
  }
`;

export const VehicleAppraisalSearchNoTermGql = gql`
  ${VehicleSearchFieldsGql}
  query VehicleAppraisalSearch(
    $limit: Int = 50
    $offset: Int = 0
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate: vehicles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles: vehicles(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      appraiser_offer: appraisals(
        order_by: { created_at: desc }
        where: { sourced_by: { _eq: "appraisal_offer" } }
        limit: 1
      ) {
        appraiser_id
        appraiser {
          name
        }
        offer
      }
    }
    appraisal_users: vehicles(
      where: { appraiser_id: { _is_null: false } }
      distinct_on: appraiser_id
    ) {
      appraiser {
        name
        id
      }
    }
  }
`;

export const VehicleAppraisalSearchGql = gql`
  ${VehicleSearchFieldsGql}
  query VehicleAppraisalSearch(
    $limit: Int = 50
    $offset: Int = 0
    $search: String!
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate(args: { search: $search }, where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles(
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      appraiser_offer: appraisals(
        order_by: { created_at: desc }
        where: { sourced_by: { _eq: "appraisal_offer" } }
        limit: 1
      ) {
        appraiser_id
        appraiser {
          name
        }
        offer
      }
    }
    appraisal_users: vehicles(
      where: { appraiser_id: { _is_null: false } }
      distinct_on: appraiser_id
    ) {
      appraiser {
        name
        id
      }
    }
  }
`;

export const VehicleAppraisalFullSearchGql = gql`
  ${VehicleSearchFieldsGql}
  query VehicleAppraisalSearch(
    $offset: Int = 0
    $search: String!
    $where: vehicles_bool_exp
    $order_by: [vehicles_order_by!]
  ) {
    search_vehicles_aggregate(args: { search: $search }, where: $where) {
      aggregate {
        count
      }
    }
    search_vehicles(
      args: { search: $search }
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...VehicleSearchOutputFields
      appraiser_offer: appraisals(
        order_by: { created_at: desc }
        where: { sourced_by: { _eq: "appraisal_offer" } }
        limit: 1
      ) {
        appraiser_id
        appraiser {
          name
        }
        offer
      }
    }
    appraisal_users: vehicles(
      where: { appraiser_id: { _is_null: false } }
      distinct_on: appraiser_id
    ) {
      appraiser {
        name
        id
      }
    }
  }
`;

export const VehicleFindByIdGql = gql`
  ${VehicleSearchStatusFieldsGql}

  query VehicleFindById($id: Int!, $limit: Int = 5) {
    vehicles(where: { id: { _eq: $id } }) {
      appraisals(order_by: { created_at: desc }) {
        id
        uuid
        appraiser_id
        created_at
        updated_at
        offer
        offer_floor
        offer_top
        offer_bb
        acv_estimate
        acv_wholesale_estimate
        sourced_by
        dealer_guaranteed_price
        dealer_guaranteed_expiry_date
        dealer_guaranteed_accepted_date
        disclosures {
          id
          acv_disclosure_id
          accident
          acv_disclosure_id
          answers_drivably
          odometer
          zip_code
          color
          disclosure_answers
          disclosure_questions
          disclosure_response
        }
      }
      inspections(order_by: { updated_at: desc }) {
        updated_at
        monk_inspection_id
        monk_inspection_status
      }
      lead_viewed
      address
      bb_uvc
      id
      km
      make
      model
      notes
      style
      trim
      transmission
      engine
      drivetrain
      vin
      year
      appointment_date
      moved_to_appointment_at
      recon_estimate
      inspection_request
      uuid
      created_at
      vehicle_images {
        id
        label
        link
        category
      }
      store {
        address
        city
        id
        name
        province_state
        zip
        store_logo_url
        calibrationID
      }
      vehicle_notes(limit: $limit, order_by: { created_at: desc }) {
        id
        vehicle_id
        note
        user_info {
          name
        }
        created_at
        updated_at
      }
      contact {
        email
        first_name
        id
        last_name
        mobile
        name
      }
      meeting {
        vehicle_id
        date
      }
      damage_report {
        vehicle_id
        vehicle_parts
      }
      ...VehicleSearchOutputStatusFields
      selected_appraisal
      customer_first_name
      customer_last_name
      customer_phone
      customer_email
      source_platform
      zip
    }
  }
`;

export const VehicleFindByUuidGql = gql`
  query VehicleFindByUuid($uuid: uuid!) {
    vehicles(where: { uuid: { _eq: $uuid } }) {
      id
      style
      trim
      vin
      km
      engine
      year
      make
      model
      uuid
      source_platform
      appraisals(order_by: { created_at: desc }) {
        id
        uuid
        acv_estimate
        acv_wholesale_estimate
        offer
        disclosures {
          id
          acv_disclosure_id
        }
      }
      store {
        id
        name
      }
      contact {
        mobile
      }
      inspections {
        id
        monk_inspection_status
        monk_inspection_id
      }
    }
  }
`;

export const VehicleFindByInspectionIdGql = gql`
  query VehicleFindByInspectionId($monk_inspection_id: uuid!) {
    vehicles(
      where: {
        inspections: { monk_inspection_id: { _eq: $monk_inspection_id } }
      }
    ) {
      inspections(order_by: { updated_at: desc }) {
        updated_at
        monk_inspection_id
        monk_inspection_status
      }
      id
      make
      model
      style
      trim
      vin
      year
      km
      recon_estimate
      engine
      drivetrain
      transmission
      vehicle_images {
        id
        label
        link
        category
      }
      store {
        address
        city
        id
        name
        province_state
        zip
        store_logo_url
      }
      contact {
        mobile
        name
        email
      }
      damage_report {
        vehicle_id
        vehicle_parts
      }
      customer_first_name
      customer_last_name
      customer_email
      customer_phone
    }
  }
`;

export const VehicleFindByInspectionIdDamageReportGql = gql`
  query VehicleFindByInspectionId($monk_inspection_id: uuid!) {
    vehicles(
      where: {
        inspections: { monk_inspection_id: { _eq: $monk_inspection_id } }
      }
    ) {
      damage_report {
        vehicle_id
        vehicle_parts
      }
    }
  }
`;

export const VehicleInspectionMsgsByUuidGql = gql`
  query VehicleInspectionMsgsByUuidGql($uuid: String!) {
    inspection_msgs(where: { content: { _ilike: $uuid } }) {
      delivered
      error_code
      id
      content
      error_text
      from
      ssid
      to
    }
  }
`;

export const VehicleInspectionFindByIdGql = gql`
  query VehicleInspectionFindById($id: Int!) {
    vehicles(where: { id: { _eq: $id } }) {
      id
      created_at
      mi
      make
      model
      offer
      offer_range
      vin
      year
      inspection_request
      inspection_request_data
      vehicle_images {
        id
        link
        category
      }
      store {
        id
        address
        city
        country
        created_at
        name
        province_state
        zip
      }
      userByOwnerId {
        name
        email
      }
    }
  }
`;

export const VehicleUpdateInspectionGql = gql`
  mutation VehicleUpdateInspection(
    $id: Int!
    $inspection_request: Boolean
    $inspection_request_data: jsonb!
  ) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: {
        inspection_request: $inspection_request
        inspection_request_data: $inspection_request_data
      }
    ) {
      id
      inspection_request
      inspection_request_data
    }
  }
`;

export const VehicleUpdateNotesGql = gql`
  mutation VehicleUpdateNotes($id: Int!, $notes: String) {
    update_vehicles_by_pk(pk_columns: { id: $id }, _set: { notes: $notes }) {
      id
      notes
    }
  }
`;

export const VehicleUpdateSelectedAppraisalGql = gql`
  mutation VehicleUpdateSelectedAppraisal($id: Int!, $appraisal_id: Int) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { selected_appraisal: $appraisal_id }
    ) {
      id
      selected_appraisal
    }
  }
`;

export const VehicleCreateNoteGql = gql`
  mutation VehicleCreateNoteGql(
    $note: String
    $vehicle_id: Int
    $user_id: Int
  ) {
    insert_notes_one(
      object: { note: $note, vehicle_id: $vehicle_id, user_id: $user_id }
    ) {
      id
    }
  }
`;

export const VehicleUpdateNoteByIdGql = gql`
  mutation VehicleUpdateNoteByIdGql($id: Int!, $note: String) {
    update_notes_by_pk(pk_columns: { id: $id }, _set: { note: $note }) {
      id
      note
    }
  }
`;

export const VehicleUpdateReconEstimateGql = gql`
  mutation VehicleUpdateReconEstimate($id: Int!, $recon_estimate: Int) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { recon_estimate: $recon_estimate }
    ) {
      recon_estimate
    }
  }
`;

export const VehicleUpdateReconNotesGql = gql`
  mutation VehicleUpdateReconNotes($id: Int!, $recon_notes: String) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { recon_notes: $recon_notes }
    ) {
      recon_notes
    }
  }
`;

export const VehicleUpdateStatusGql = gql`
  mutation VehicleUpdateStatus(
    $vehicle_id: Int!
    $_set: vehicle_status_set_input!
  ) {
    update_vehicle_status_by_pk(
      pk_columns: { vehicle_id: $vehicle_id }
      _set: $_set
    ) {
      vehicle {
        id
      }
    }
  }
`;

export const VehicleUpdateMeetingGql = gql`
  mutation VehicleUpdateMeeting($vehicle_id: Int!, $date: timestamptz!) {
    update_meetings_by_pk(
      pk_columns: { vehicle_id: $vehicle_id }
      _set: { date: $date }
    ) {
      vehicle_id
      date
    }
  }
`;

export const VehicleCreateMeetingGql = gql`
  mutation VehicleCreateMeetingGql($vehicle_id: Int!, $date: timestamptz!) {
    insert_meetings_one(object: { vehicle_id: $vehicle_id, date: $date }) {
      vehicle_id
      date
    }
  }
`;

export const VehicleCreateDamageReportGql = gql`
  mutation VehicleCreateDamageReportGql(
    $vehicle_parts: jsonb = ""
    $vehicle_id: Int!
  ) {
    insert_damage_reports_one(
      object: { vehicle_id: $vehicle_id, vehicle_parts: $vehicle_parts }
    ) {
      vehicle_id
      vehicle {
        id
      }
    }
  }
`;

export const VehicleUpdateDamageReportGql = gql`
  mutation VehicleUpdateDamageReportGql(
    $vehicle_id: Int!
    $vehicle_parts: jsonb = ""
  ) {
    update_damage_reports_by_pk(
      pk_columns: { vehicle_id: $vehicle_id }
      _set: { vehicle_parts: $vehicle_parts }
    ) {
      vehicle_id
      vehicle_parts
    }
  }
`;

export const ContactUpdateVehicleDetailsGql = gql`
  mutation ContactUpdateVehicleDetails(
    $email: String
    $firstName: String
    $lastName: String
    $mobile: String
    $vehicleId: Int!
    $zip_code: String
  ) {
    update_vehicles_by_pk(
      pk_columns: { id: $vehicleId }
      _set: {
        customer_first_name: $firstName
        customer_last_name: $lastName
        customer_email: $email
        customer_phone: $mobile
        zip: $zip_code
      }
    ) {
      id
      customer_first_name
      customer_last_name
      customer_email
      customer_phone
      zip
    }
  }
`;

export const ContactUpdateDisclosureDetailsGql = gql`
  mutation ContactUpdateDisclosureDetails(
    $disclosureId: Int!
    $zip_code: String
  ) {
    update_disclosures_by_pk(
      pk_columns: { id: $disclosureId }
      _set: { zip_code: $zip_code }
    ) {
      id
      zip_code
    }
  }
`;

export const VehicleFindAppraisalsByIdGql = gql`
  query VehicleFindAppraisalsById($id: Int!) {
    vehicles(where: { id: { _eq: $id } }) {
      adjusted_whole_avg
      adjusted_whole_clean
      adjusted_whole_rough
      adjusted_whole_xclean
      appraiser_id
      id
      make
      mi
      model
      store_id
      style
      trim
      uuid
      vin
      year
      appraisals(order_by: { created_at: desc }) {
        id
        mode
        offer
        offer_floor
        offer_top
        offer_bb
        offer_acv
        offer_range_acv
        offer_displayed
        created_at
        sourced_by
        disclosures {
          id
          acv_disclosure_id
          color
          zip_code
          odometer
          accident
          trade_in
          condition
          disclosure_questions
          disclosure_answers
          disclosure_response
        }
      }
    }
  }
`;

export const VehicleFindExtrasByIdGql = gql`
  query VehicleFindExtrasById($id: Int!) {
    vehicles(where: { id: { _eq: $id } }) {
      id
      extra
    }
  }
`;

export const FindVehicleIdByDisclosureGql = gql`
  query FindVehicleIdByDisclosure($id: Int!) {
    disclosures_by_pk(id: $id) {
      id
      vehicle_id
    }
  }
`;

export const VehicleUpdateExtraGql = gql`
  mutation VehicleUpdateExtra($id: Int!, $extra: jsonb!) {
    update_vehicles_by_pk(pk_columns: { id: $id }, _set: { extra: $extra }) {
      id
      extra
    }
  }
`;

export const VehicleUpdateLeadViewedGql = gql`
  mutation VehicleUpdateLeadViewed($id: Int!, $lead_viewed: Boolean!) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { lead_viewed: $lead_viewed }
    ) {
      id
      lead_viewed
    }
  }
`;

export const VehicleCreateGql = gql`
  mutation VehicleCreate(
    $active: Boolean = false
    $bb_uvc: String = ""
    $appraisal: Appraisal = {}
    $contact: Contact = {}
    $description: String = ""
    $km: Int = 10
    $make: String = ""
    $model: String = ""
    $style: String
    $trim: String = ""
    $vin: String = ""
    $year: Int = 10
    $source_link: String = ""
    $source_platform: String = ""
    $store_id: Int
    $title: String = ""
    $zip: String
    $utm_data: String
    $referrer_link: String
    $color: String
    $engine: String = ""
    $transmission: String = ""
    $drivetrain: String = ""
  ) {
    addVehicle(
      active: $active
      bb_uvc: $bb_uvc
      appraisal: $appraisal
      contact: $contact
      description: $description
      km: $km
      make: $make
      model: $model
      style: $style
      trim: $trim
      vin: $vin
      year: $year
      source_link: $source_link
      source_platform: $source_platform
      store_id: $store_id
      title: $title
      zip: $zip
      utm_data: $utm_data
      referrer_link: $referrer_link
      color: $color
      engine: $engine
      transmission: $transmission
      drivetrain: $drivetrain
    ) {
      appraisalId: appraisal_id
      vehicleAdded: vehicle_added
      vehicleExists: vehicle_exists
      vehicleUuid: vehicle_uuid
      contactId: contact_id
    }
  }
`;
